@use "./node_modules/include-media/dist/_include-media.scss" as *;
.info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgb(var(--link-white-rgb));
    position: absolute;
    z-index: 2;
    left: 1rem;
    bottom: 1rem;
    border-radius: 99px;
    background: rgba(0 0 0 / 80%);
    padding: 0.75rem 1rem;

    @include media('<desktop') {
        bottom: auto;
        top: 1rem;
    }
}
