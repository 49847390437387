@use "./node_modules/include-media/dist/_include-media.scss" as *;
.nav {
    border-bottom: 2px solid rgb(255 255 255 / 10%);
}

.navUl {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    margin-bottom: -2px;

    @include media('<tablet') {
        display: block;
        margin: 0;
    }
}

.navLi {
    @include media('<tablet') {
        display: block;
        text-align: center;
        padding: 0.75rem 0;
    }
}

.navA {
    display: block;
    font-size: 1.0625rem;
    font-weight: 350;
    padding: 0.75rem 0;
    border-bottom: 2px solid transparent;
    transition: all var(--transition-duration);

    @include media('<tablet') {
        display: inline;
        border-bottom: 1px solid transparent;
        padding: 0.75rem;
    }
}

.navActive {
    font-weight: 600;
    border-bottom-color: rgb(255 255 255 / 40%);
}
