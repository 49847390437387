@use "./node_modules/include-media/dist/_include-media.scss" as *;
.teasers {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 2rem 3rem;
    max-width: 1280px;
    margin: 0 auto;

    @include media('<tablet') {
        display: flex;
        flex-direction: column;
    }
}

.outerModal {
    /* override modal height */
    height: auto !important;
}
