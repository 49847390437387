@use "./node_modules/include-media/dist/_include-media.scss" as *;
.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;
    opacity: 0;
    transition: opacity var(--transition-duration);
    z-index: 1;
}

.imageLoaded {
    opacity: 1;
}
