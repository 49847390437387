@use "./node_modules/include-media/dist/_include-media.scss" as *;
.tile {
    background: var(--bg-paper);
    border-top: 2px solid rgba(255 255 255 / 10%);
    transition: all var(--transition-duration);
    box-shadow: 0 0.5rem 1.25rem rgba(0 0 0 / 10%);
}

.link {
    display: block;
    height: 100%;
}

.inner {
    padding: 1.75rem 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    :global(p) {
        @include media('>=tablet') {
            font-size: 1.125rem;
        }
    }

    .image + & {
        padding-top: 1.25rem;
    }
}

.tile:has(.link:hover) {
    box-shadow: 0 0.5rem 1.5rem rgba(0 0 0 / 25%);

    .inner {
        filter: brightness(1.1);
    }
}

.more {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity var(--transition-duration);
}

.imageLoaded {
    opacity: 1;
}
